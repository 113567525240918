<template>
  <v-app>
    <div>
       <v-alert
        v-model="alert"
        border="left"
        dark
        dismissible
        class="alert"
        :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
      >
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Allowance Setup</h1>
        </div>
        <v-form v-model="valid">
          <v-row no-gutters dense>
            <v-col>
               <SelectField
                  :items="workAreas"
                  v-model="formData.WorkAreaId"
                  :label="'Work Area'"
                  :itemText="'name'"
                  :itemValue="'id'"
                />
            </v-col>
            <v-col>
               <SelectField
                  :items="rotations"
                  v-model="formData.rotationId"
                  :label="'work schedule'"
                  :itemText="'name'"
                  :itemValue="'id'"
                />
            </v-col>
            <v-col>
               <SelectField
                  :items="salaryComponents"
                  v-model="formData.SalaryComponentId"
                  :label="'Salary Component'"
                  :itemText="'description'"
                  :itemValue="'id'"

                  @onChange="getInputLabel"
                />
            </v-col>
            <v-col>
              <TextField v-model="formData.rate" :label="label" :type="'number'"/>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="onSave"
                      :disabled="!valid"
                      :isLoading="isSavingUser"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>

    </div>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import { CREATE_BENEFIT } from '@/store/action-type'
import {
  workAreaService,
  salaryComponentService,
  rotationService
} from '@/services'
export default {
  components: {
    TextField,
    SelectField,
    Button
  },
  data () {
    return {
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isSavingUser: false,
      formData: {
        WorkAreaId: '',
        rotationId: '',
        SalaryComponentId: '',
        rate: 0.0,
        action: 'New'
      },
      label: 'Rate',
      workAreas: [],
      rotations: [

      ],
      salaryComponents: []
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isSavingUser = true
      const data = { 'WorkAreaId': this.formData.WorkAreaId, 'rotationId': this.formData.rotationId, 'SalaryComponentId': this.formData.SalaryComponentId, 'rate': this.formData.rate, 'action': 'New' }

      console.log(data)
      this.$store.dispatch(CREATE_BENEFIT, data).then(() => {
        this.showAlertMessage('Benefits Setup creation successful', 'success')
      }).catch(() => {
        this.showAlertMessage('Benefits Setup creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        // this.onReset()
      })
    },
    onReset () {
      this.$refs.benefitsetup.reset()
    },
    getInputLabel (item) {
      salaryComponentService.getLabel(item).then(result => {
        this.label = result.data.label
      }).catch(() => {
      })
    }
  },
  mounted () {
    let self = this
    workAreaService.getAllWorkArea().then((result) => {
      self.workAreas = result.data.items
    })

    salaryComponentService.getAllSalaryAllowance().then((result) => {
      self.salaryComponents = result.data
    })

    rotationService.getAllRotation().then((result) => {
      self.rotations = result.data.items
    }).catch(error => {
      console.log('RotationService', error)
    })
  }
}
</script>
<style scoped>
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
